import React from 'react'
import styled from 'styled-components'
import WorksListItem from '../WorksListItem'
import { Wrapper, WorksTitle, ButtonsWrapper, FilterButton, NDAWrapper, StyledDivider, StyledEmailMe } from './style'


const WorksListSection = ({ 
  works, 
  category, 
  setCategory 
}) => 
  <Wrapper>
    <header>
      <WorksTitle>SARS-CoV-2
</WorksTitle>
    </header>
    <main>
      {works.map(({ work }, i) => <WorksListItem key={i} work={work} />)}
    </main>
  </Wrapper>

export default WorksListSection
